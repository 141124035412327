import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { PageContentLayout } from '../../components/PageContentLayout';
import { Sandbox } from '../../components/Sandbox';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageContentLayout headProps={{
  title: 'Animation'
}} {...props} />;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1><inlineCode parentName="h1">{`@arwes/animation`}</inlineCode></h1>
    <p>{`Assemble and disassemble user interfaces using animation controls in `}<a parentName="p" {...{
        "href": "https://reactjs.org"
      }}>{`React`}</a>{`.`}</p>
    <p>{`Based on the `}<a parentName="p" {...{
        "href": "/project/guidelines"
      }}>{`UI/UX guidelines`}</a>{`, the animation tools can be
used to manage and control `}<a parentName="p" {...{
        "href": "/project/guidelines/states"
      }}>{`animation flows`}</a>{` in
applications. Then the specific components animations or components events can
be defined more easily, such as visual effects or sound playbacks when a
component transition from one state to another.`}</p>
    <p>{`The animation tools require the `}<a parentName="p" {...{
        "href": "https://reactjs.org"
      }}>{`React`}</a>{` component tree to
allow the dynamic communication between components.`}</p>
    <p>{`It goes hand in hand with the `}<a parentName="p" {...{
        "href": "/develop/sounds"
      }}>{`sounds tools`}</a>{`, though it is not
a dependency.`}</p>
    <h2>{`Installation`}</h2>
    <p>{`All the tools are bundled and can be installed with the following NPM package:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install @arwes/animation
`}</code></pre>
    <p>{`The animation management tooling requires React v17 as a peer-dependency.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install react@17 react-dom@17 prop-types
`}</code></pre>
    <p>{`In TypeScript, the following type packages are needed:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install @types/react@17 @types/react-dom@17
`}</code></pre>
    <h2>{`Animators`}</h2>
    <p>{`Any React component can have animation controls and be added to the
`}<a parentName="p" {...{
        "href": "/project/guidelines/systems"
      }}>{`Arwes system`}</a>{` by using the `}<inlineCode parentName="p">{`<Animator/>`}</inlineCode>{` component.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`<Animator/>`}</inlineCode>{` component will work as an animated node in the system. It will
be interconnected by sharing data and communicating with its parent and
children counterparts.`}</p>
    <p>{`Normally, the `}<inlineCode parentName="p">{`withAnimator`}</inlineCode>{` `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/higher-order-components.html"
      }}>{`higher-order component (HOC)`}</a>{`
will handle the animator component setup instead of the component itself.
So class/function components with specific initial settings can be created.`}</p>
    <p>{`A HTML button component can be animated like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import React, { FC, useRef, MutableRefObject } from 'react';
import { AnimatorRef, AnimatorClassSettings, withAnimator } from '@arwes/animation';

interface ButtonProps {
  // An animated component will receive the \`animator\` prop with
  // the animator API.
  animator: AnimatorRef
}

const ButtonComponent: FC<ButtonProps> = props => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  // Here you can set any kind of data you want to pass to the
  // animator "animate events". In this case, a HTML button ref.
  props.animator.setupAnimateRefs(buttonRef);

  return (
    <button ref={buttonRef}>
      {props.children}
    </button>
  );
};

// "enter" is the duration to transition from exited to entering to entered.
// "exit" is the duration to transition from entered to exiting to exited.
const duration = { enter: 200, exit: 200 };

const onAnimateEntering = (
  animator: AnimatorRef,
  buttonRef: MutableRefObject<HTMLButtonElement>
): void => {
  // On flow entering:
  // You can animate the references passed as the second parameter.
};

const onAnimateExiting = (
  animator: AnimatorRef,
  buttonRef: MutableRefObject<HTMLButtonElement>
): void => {
  // On flow exiting:
  // You can animate the references passed as the second parameter.
};

const animatorClassSettings: AnimatorClassSettings = {
  duration,
  onAnimateEntering,
  onAnimateExiting
};

const Button = withAnimator(animatorClassSettings)(ButtonComponent);

export { ButtonProps, Button };
`}</code></pre>
    <p>{`The new wrapped component `}<inlineCode parentName="p">{`<Button/>`}</inlineCode>{` by the HOC will have animations
enabled and it will handle when to call the animate events on flow transitions.`}</p>
    <p>{`But this component will not execute any HTML/CSS animations on its elements,
so we need to check how to add them in the animate events.`}</p>
    <h2>{`Animate Events`}</h2>
    <p>{`An animator will only handle the flow transitions and flow control management.
The actual HTML elements animations should be handled inside the animate events.`}</p>
    <p>{`By setting up the references to the HTML elements inside the component render
with `}<inlineCode parentName="p">{`props.animator.setupAnimateRefs()`}</inlineCode>{`, the events will receive them for
manipulations.`}</p>
    <p>{`The HTML elements animations can be made with any HTML animation library.
Recommended libraries are `}<a parentName="p" {...{
        "href": "https://animejs.com"
      }}>{`animejs`}</a>{` and `}<a parentName="p" {...{
        "href": "https://greensock.com"
      }}>{`GreenSock`}</a>{`.`}</p>
    <p>{`If the example `}<inlineCode parentName="p">{`<Button/>`}</inlineCode>{` component should have animations:`}</p>
    <ul>
      <li parentName="ul">{`To enter the flow with opacity from `}<inlineCode parentName="li">{`0`}</inlineCode>{` to `}<inlineCode parentName="li">{`1`}</inlineCode>{` and time `}<inlineCode parentName="li">{`duration.enter`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`To exit the flow with opacity from `}<inlineCode parentName="li">{`1`}</inlineCode>{` to `}<inlineCode parentName="li">{`0`}</inlineCode>{` and time `}<inlineCode parentName="li">{`duration.exit`}</inlineCode>{`.`}</li>
    </ul>
    <p>{`They could be written like this using `}<a parentName="p" {...{
        "href": "https://animejs.com"
      }}>{`animejs`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import anime from 'animejs';

// ...

const onAnimateEntering = (
  animator: AnimatorRef,
  buttonRef: MutableRefObject<HTMLButtonElement>
): void => {
  anime({
    targets: buttonRef.current,
    duration: animator.duration.enter,
    easing: 'linear',
    opacity: [0, 1]
  });
};

const onAnimateExiting = (
  animator: AnimatorRef,
  buttonRef: MutableRefObject<HTMLButtonElement>
): void => {
  anime({
    targets: buttonRef.current,
    duration: animator.duration.exit,
    easing: 'linear',
    opacity: [1, 0]
  });
};

// ...
`}</code></pre>
    <p>{`When the flow enters, it would go from hidden to visible. When the flow exits,
it will reverse it.`}</p>
    <p>{`There is a event for all the flow states transitions and the component mounting
and unmounting lifecycles.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`onAnimateMount`}</inlineCode>{` - On component mount.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`onAnimateEntering`}</inlineCode>{` - On flow entering.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`onAnimateEntered`}</inlineCode>{` - On flow entered.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`onAnimateExiting`}</inlineCode>{` - On flow exiting.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`onAnimateExited`}</inlineCode>{` - On flow exited.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`onAnimateUnmount`}</inlineCode>{` - On component unmount.`}</li>
    </ul>
    <p>{`Now, according to how the component is used, it will behave in a certain way.`}</p>
    <h2>{`Parent/Children Nodes`}</h2>
    <p>{`By default, if the component is used alone or if it does not have parent
counterparts, then it is considered a root component, unless configured otherwise.`}</p>
    <p>{`If the component is a root node, on component mount, by default its flow will
transition from `}<inlineCode parentName="p">{`exited`}</inlineCode>{` to `}<inlineCode parentName="p">{`entering`}</inlineCode>{` to `}<inlineCode parentName="p">{`entered`}</inlineCode>{` and stay there.`}</p>
    <p>{`If the component is a child node, it will always listen to its closest parent node
flow state to know when it should transition. See more in
`}<a parentName="p" {...{
        "href": "/develop/animation/nesting"
      }}>{`Nesting Animators`}</a>{`.`}</p>
    <p>{`Normally, the root nodes are managed using its instance settings, and the children
nodes are managed by their parent nodes.`}</p>
    <h2>{`Instance Settings`}</h2>
    <p>{`An animated component when it is used, it can receive an `}<inlineCode parentName="p">{`animator`}</inlineCode>{` settings
object to configure declaratively its animator.`}</p>
    <p>{`The root nodes can receive the property `}<inlineCode parentName="p">{`animator.activate`}</inlineCode>{` to determine
when it should enter or exit the animation flow. This, in turn, will manage
its non-root children nodes accordingly.`}</p>
    <p>{`In the example of the `}<inlineCode parentName="p">{`<Button/>`}</inlineCode>{` component, it could receive instance
settings like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import { AnimatorInstanceSettings, ... } from '@arwes/animation';

// ...

const instanceSettings: AnimatorInstanceSettings = {
  // true to make the component transition in.
  // false to make the component transition out.
  activate: true
};

// ...

<Button animator={instanceSettings}>
  My Animated Button
</Button>

// ...
`}</code></pre>
    <p>{`A complete example using the `}<inlineCode parentName="p">{`<Button/>`}</inlineCode>{` component as a root node can look
like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-arwes_sandbox"
      }}>{`function ButtonComponent (props) {
  const buttonRef = React.useRef();
  props.animator.setupAnimateRefs(buttonRef);
  return (
    <button ref={buttonRef} style={{ opacity: 0 }}>
      {props.children}
    </button>
  );
}

const duration = { enter: 200, exit: 200 };

function onAnimateEntering (animator, buttonRef) {
  anime({
    targets: buttonRef.current,
    duration: animator.duration.enter,
    easing: 'linear',
    opacity: [0, 1]
  });
}

function onAnimateExiting (animator, buttonRef) {
  anime({
    targets: buttonRef.current,
    duration: animator.duration.enter,
    easing: 'linear',
    opacity: [1, 0]
  });
}

const Button = withAnimator({
  duration,
  onAnimateEntering,
  onAnimateExiting
})(ButtonComponent);

function App () {
  const [activate, setActivate] = React.useState(true);
  const timeout = React.useRef();

  React.useEffect(() => {
    timeout.current = setTimeout(() => setActivate(!activate), 2000);
    return () => clearTimeout(timeout.current);
  }, [activate]);

  return (
    <Button animator={{ activate }}>
      My Button
    </Button>
  );
}

render(<App />);
`}</code></pre>
    <p>{`It will update the component flow activation every 2 seconds.`}</p>
    <p>{`Since it is an animated component, by default, its flow will be `}<inlineCode parentName="p">{`exited`}</inlineCode>{`. Then
if it is activated, it will transition from `}<inlineCode parentName="p">{`exited`}</inlineCode>{` to `}<inlineCode parentName="p">{`entering`}</inlineCode>{` to `}<inlineCode parentName="p">{`entered`}</inlineCode>{`.
When it transitions to `}<inlineCode parentName="p">{`entering`}</inlineCode>{`, the event `}<inlineCode parentName="p">{`onAnimateEntering`}</inlineCode>{` will be called.`}</p>
    <p>{`When it is deactivated, it will transition from `}<inlineCode parentName="p">{`entered`}</inlineCode>{` to `}<inlineCode parentName="p">{`exiting`}</inlineCode>{` to `}<inlineCode parentName="p">{`exited`}</inlineCode>{`.
When it transitions to `}<inlineCode parentName="p">{`exiting`}</inlineCode>{`, the event `}<inlineCode parentName="p">{`onAnimateExiting`}</inlineCode>{` will be called.`}</p>
    <p>{`The component should set the initial styles of the animator animations changes.
In this case, the component is set to `}<inlineCode parentName="p">{`opacity: 0`}</inlineCode>{` when it is `}<inlineCode parentName="p">{`exited`}</inlineCode>{` in the
animation. Since its initial value is `}<inlineCode parentName="p">{`exited`}</inlineCode>{` and the events are not executed,
the component should set the initial CSS styles to `}<inlineCode parentName="p">{`opacity: 0`}</inlineCode>{` too for
consistency.`}</p>
    <p>{`When an animated components exits, it is not unmounted.`}</p>
    <p>{`When `}<a parentName="p" {...{
        "href": "/develop/animation/nesting"
      }}>{`nesting animators`}</a>{`, the children nodes will
listen to their parent nodes so there is not management needed for them, it would
be automatically handled by the animation tools.`}</p>
    <h2>{`General Settings`}</h2>
    <p>{`Usually, the application animated components would have certain similar settings
for consistency. The available general settings can be provided using the
`}<inlineCode parentName="p">{`<AnimatorGeneralProvider/>`}</inlineCode>{` provider.`}</p>
    <p>{`For example, to provide common animator durations to all animated children nodes,
something like this can be done:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import React, { FC } from 'react';
import { AnimatorGeneralProvider, AnimatorGeneralProviderSettings } from '@arwes/animation';

const animatorGeneralSettings: AnimatorGeneralProviderSettings = {
  duration: { enter: 200, exit: 100 }
};

// ...

<AnimatorGeneralProvider animator={animatorGeneralSettings}>
  {/* ... */}
</AnimatorGeneralProvider>

// ...
`}</code></pre>
    <p>{`Currently, only the setting `}<inlineCode parentName="p">{`duration`}</inlineCode>{` can be setup this way.`}</p>
    <h2>{`Suspend On Visibility Change`}</h2>
    <p>{`Some HTML animation libraries like `}<a parentName="p" {...{
        "href": "https://animejs.com"
      }}>{`animejs`}</a>{` pause the
animations when the browser window looses focus. In animejs this behavior
can be disabled using:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import anime from 'animejs';

anime.suspendWhenDocumentHidden = false;
`}</code></pre>
    <p>{`You can see more at `}<a parentName="p" {...{
        "href": "https://animejs.com/documentation/#visibilitychange"
      }}>{`anime - suspend on visibility change`}</a>{`.`}</p>
    <hr></hr>
    <p>{`You can see and play with more examples in the `}<a parentName="p" {...{
        "href": "https://playground.arwes.dev/animation/withAnimator/basic"
      }}>{`playground`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      